<template>
  <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules" #default="{ errors }">
    <b-form-input
      v-bind="$attrs"
      v-model.trim="innerValue"
      :state="errors.length > 0 ? false : null"
      autocomplete="off"
    />
    <field-error-text>{{ errors[0] }}</field-error-text>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import FieldErrorText from '@/components/inputs/FieldErrorText.vue';

export default {
  name: 'InputWithValidation',
  components: {
    ValidationProvider,
    FieldErrorText,
  },
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: null,
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) this.innerValue = this.value;
  },
};
</script>

<style></style>
